<template>
  <div>
    <a-card>
      <template slot="title">
        <strong>Form Rilis Invoice</strong>
        <a-button-group class="ml-2">
        <a-button class="btn btn-outline-primary" @click="handleSubmit" :disabled="rowData.length === 0"
          >Simpan</a-button
        >
        <a-button v-if="action === 'update'" class="btn btn-outline-danger" @click="resetForm(true)"
          >Cancel Update</a-button
        >
        <a-button class="btn btn-outline-danger" @click="back"
          >Back</a-button
        >
      </a-button-group>
      </template>
    <div class="row pl-1 pr-1 pt-1 pb-1">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="row">
          <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-1">
            <strong class="">Tgl Invoice</strong>
          </div>
          <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                  <a-date-picker
                    style="width: 125px;"
                    v-model="maskInput.tgl_invoice"
                    :allowClear="false"
                    format="DD-MM-YYYY"
                  />
          </div>
          <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-1">
            <label class="">Customer</label>
          </div>
          <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
              <a-input
              style="width: 80%;"
              placeholder="Ketikan Nama Customer"
              :readOnly="true"
              v-model="input.nama_customer"
            ></a-input>
            <!-- <v-select
                  ref="employee_id"
                  placeholder="Ketikan Nama Sales"
                  class="style-chooser"
                  label="label"
                  style="width: 80%;"
                  v-model="input.employee_id"
                  :options="masterEmployee"
                  :reduce="(tes) => tes.id"
                  @input="nextFocus('keteranganinput')"
                  /> -->
                </div>
                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2">
                  <label class="">Tanggal Transaksi</label>
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1">
              <a-input
              style="width: 125px;"
              placeholder="Tgl"
              :readOnly="true"
              v-model="input.tgl"
            ></a-input>
                  <!-- <a-date-picker
                    style="width: 125px;"
                    v-model="input.tgl"
                    :allowClear="false"
                    format="DD-MM-YYYY"
                  /> -->
                  <!-- <a-button
                    class="btn btn-outline-danger ml-2"
                    v-if="selectedData.length !== 0"
                    @click="bataledit"
                    >Batalkan Edit</a-button
                  > -->
                </div>
          <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-1">
            <label class="">Sales</label>
          </div>
          <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
              <a-input
              style="width: 80%;"
              placeholder="Ketikan Nama Sales"
              :readOnly="true"
              v-model="input.nama_employee"
            ></a-input>
            <!-- <v-select
                  ref="employee_id"
                  placeholder="Ketikan Nama Sales"
                  class="style-chooser"
                  label="label"
                  style="width: 80%;"
                  v-model="input.employee_id"
                  :options="masterEmployee"
                  :reduce="(tes) => tes.id"
                  @input="nextFocus('keteranganinput')"
                  /> -->
                </div>
          <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2">
            <label class="">Catatan Order</label>
          </div>
          <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10 mt-1">
            <!-- <a-textarea
              placeholder="Ketikan catatan tentang pesanan disini!"
              :auto-size="{ minRows: 2, maxRows: 6 }"
              v-model="input.catatan"
            /> -->
              <a-input
              style="width: 70%;"
              placeholder="Ketikan catatan tentang pesanan disini!"
              :readOnly="true"
              v-model="input.catatan"
            ></a-input>
          </div>
          <!-- <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
            <v-select
              ref="selectedproduct"
              placeholder="Ketikan Nama Barang"
              class="style-chooser"
              label="label"
              style="width: 100%;"
              v-model="maskInput.selectedproduct"
              :options="masterBarang"
              :reduce="(tes) => tes.id"
              @input="selectProduk"
              />
          </div>
          <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2">
            <a-input
              class=""
              placeholder="Satuan"
              v-model="maskInput.satuan"
              ref="satuaninput"
            ></a-input>
          </div>
          <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2">
            <cleave
              ref="jumlah"
              :options="{ numeral: true }"
              class="ant-input mr-1"
              :raw="true"
              style="text-align: right;"
              v-model="maskInput.jumlah"
              placeholder="Jumlah"
              @keydown.native.enter="addDataToGrid"
            />
          </div>
          <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2">
            <cleave
              ref="harga"
              :options="{ numeral: true }"
              class="ant-input mr-1"
              :raw="true"
              style="text-align: right;"
              v-model="maskInput.harga"
              placeholder="Harga"
              @keydown.native.enter="addDataToGrid"
            />
          </div>
          <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2">
        <a-button-group>
            <a-button
              class="btn btn-outline-success"
              v-if="selectedData.length !== 0"
              @click="addDataToGrid"
              ><a-icon type="check"></a-icon> Save</a-button
            >
            <a-button
              class="btn btn-outline-primary"
              v-else
              @click="addDataToGrid"
              ><a-icon type="plus"></a-icon> Add</a-button
            >
            <a-button
              class="btn btn-outline-danger"
              v-if="selectedData.length !== 0"
              @click="removeFromGrid"
              ><a-icon type="delete"></a-icon> Remove</a-button
            >
            </a-button-group>
          </div> -->
        </div>
      </div>
      <!-- <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
        <table class="mt-3">
          <tr>
            <th>Action</th>
          </tr>
          <tr v-for="(d, i) in rowData" :key="i">
            <td>
        <a-button-group>
              <a-button href="javascript:void(0)" @click="selectProduk" class="btn btn-outline-warning">
                <a-icon class="" type="minus"></a-icon>1
              </a-button>
              <a-button href="javascript:void(0)" @click="remove()" class="btn btn-outline-danger">
                <a-icon class="" type="delete"></a-icon> {{ d.kode }}
              </a-button>
              <a-button href="javascript:void(0)" @click="selectProduk" class="btn btn-outline-success">
                <a-icon class="" type="plus"></a-icon>1
              </a-button>
            </a-button-group>
            </td>
            <td>
            </td>
          </tr>
        </table>
      </div> -->
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <ag-grid-vue
          style="height: 31vh;width: 100%;"
          :class="
            settings.theme === 'dark'
              ? 'ag-theme-balham-dark mt-2'
              : 'ag-theme-balham mt-2'
          "
          :rowClassRules="rowClassRules"
          :key="componentKey"
          :gridOptions="gridOptions"
          :columnDefs="columnDefs"
          :pagination="false"
          :accentedSort="false"
          :sortingOrder="['asc', 'desc']"
          :rowData="rowData"
          @grid-ready="onGridReady"
          :singleClickEdit="false"
          :undoRedoCellEditing="true"
          :undoRedoCellEditingLimit="5"
          rowSelection="single"
          @first-data-rendered="onFirstDataRendered"
          @selection-changed="onSelectionChanged"
          @cell-value-changed="onCellValueChanged"
          ></ag-grid-vue>
          <!-- :frameworkComponents="frameworkComponents" -->
      </div>
      <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
        <div class="row">
          <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-1">
        <strong class="mt-2 mr-1">Sub Total:</strong>
          </div>
          <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
            <a-input
              class=""
              placeholder="Sub Total"
              :readOnly="true"
              style="text-align: right; width: 150px;"
              v-model="masktherealsubtotal"
              ref="therealsubtotal"
            ></a-input>
          </div>
        </div>
      </div>
      <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
        <div class="row">
          <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-1">
        <strong class="">Diskon</strong>
          </div>
          <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
              <a-input
              style="text-align: right; width: 150px;"
              placeholder=""
              :readOnly="true"
              v-model="input.diskon"
            ></a-input>
          </div>
        </div>
      </div>
      <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
        <div class="row">
          <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-1">
        <strong class="mt-2 mr-1">Total: </strong>
          </div>
          <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
            <a-input
              class=""
              placeholder="Total"
              :readOnly="true"
                      style="text-align: right; width: 150px;"
              v-model="masktherealtotal"
              ref="therealtotal"
            ></a-input>
        <!-- <cleave
        ref=""
        :options="{ numeral: true }"
        class="ant-input ml-2 mb-1 mt-1"
        :raw="true"
        style="text-align: right; width: 150px;"
        v-model=""
        placeholder="Total"
        /> -->
          </div>
        </div>
      </div>
    </div>
    </a-card>
    <!-- <a-button
              ref="addBtn"
              class="ml-2 btn btn-outline-primary"
              @click="addNullData"
            >
              Tambah
            </a-button> -->
  </div>
</template>

<script>
import moment from 'moment'
import * as lou from '@/services/data/lou'
import numericInput from './component/numeric'
import selectInput from './component/select'
import dateInput from './component/date'
import tooltip from './component/tooltip'
import htmlrender from './component/htmlrender'
import removeData from './component/removeData'
// import Cleave from 'vue-cleave-component'
import { Modal } from 'ant-design-vue'
// import vueCustomScrollbar from 'vue-custom-scrollbar'

import { mapState } from 'vuex'

export default {
  computed: mapState(['settings', 'user']),
  components: {
    // vueCustomScrollbar,
    // cleave: Cleave,
  },
  created() {
    this.gridOptions = {}
    this.gridApi = this.gridOptions.api
    this.gridColumnApi = this.gridOptions.columnApi
    this.getEditData()
    // this.getMaster()
  },
  data() {
    return {
      action: 'create',
      title: 'Create Rilis Invoice',
      tgl1: moment(),
      editdata: JSON.parse(localStorage.getItem('projectatransaksirilisinvoice')),
      masterCustomer: [],
      masterEmployee: [],
      masterBarang: [],
      therealsubtotal: '0',
      therealtotal: '0',
      masktherealsubtotal: '0',
      masktherealtotal: '0',
      maskInput: {
        sales_order_id: '',
        tgl_invoice: moment(),
      },
      input: {
        customer_id: '',
        employee_id: '',
        catatan: '',
        tgl: moment(),
        details: [],
        subtotal: '0',
        diskon: '0',
        total: '0',
      },

      // Property a-table
      componentKeyDatatable: 0,
      // End of property

      // ag-grid-property
      rowClassRules: {
        'text-success': (params) => {
          return params.data.newdata !== undefined
        },
        'text-warning': (params) => {
          return params.data.edited !== undefined
        },
        'text-danger': (params) => {
          return params.data.isError !== undefined
        },
        'text-primary': (params) => {
          return params.data.isPrimary !== undefined && params.data.isPrimary === 1
        },
        'font-weight-bold': (params) => {
          return params.data.isBold !== undefined
        },
      },
      changedData: [],
      frameworkComponents: {
        numericEditor: numericInput,
        dateEditor: dateInput,
        selectEditor: selectInput,
        customTooltip: tooltip,
        htmlRender: htmlrender,
        removeData: removeData,
      },
      selectedData: [],
      columnDefs: [
        // {
        //   headerName: '#',
        //   width: 50,
        //   editable: false,
        //   resizable: false,
        //   sortable: false,
        //   cellRenderer: 'removeData',
        // },
        {
          headerName: 'Kode',
          field: 'kode',
          width: 80,
          editable: false,
          resizable: true,
          sortable: true,
        },
        {
          headerName: 'Nama',
          field: 'nama',
          editable: false,
          resizable: true,
          sortable: true,
        },
        {
          headerName: 'Satuan',
          field: 'satuan',
          editable: false,
          resizable: true,
          width: 120,
          sortable: true,
        },
        {
          headerName: 'Jumlah',
          field: 'jumlah',
          width: 80,
          editable: true,
          resizable: true,
          cellEditor: 'numericEditor',
          sortable: true,
          type: 'rightAligned',
          // valueGetter: params => {
          //   // console.log('params.data.jumlah', params.data.jumlah)
          //   return params.data.jumlah
          // },
        },
        {
          headerName: 'Harga',
          field: 'harga',
          editable: false,
          resizable: true,
          sortable: true,
          width: 150,
          type: 'rightAligned',
          valueFormatter: (params) => {
            // console.log('params', params)
            var ret = params.value === null ? 'NaN' : lou.curency(params.value)
            return ret === 'NaN' ? '' : ret
          },
        },
        {
          headerName: 'Total Harga',
          isBold: 'true',
          valueGetter: params => {
            // console.log('params.data.jumlah', params.data.jumlah)
            var total = parseInt(params.data.harga) * parseInt(params.data.jumlah)
            return total
          },
          editable: false,
          resizable: true,
          sortable: true,
          width: 150,
          type: 'rightAligned',
          valueFormatter: (params) => {
            // console.log('params', params)
            var ret = params.value === null ? 'NaN' : lou.curency(params.value)
            return ret === 'NaN' ? '' : ret
          },
        },
      ],
      rowData: [],
      gridOptions: null,
      gridApi: null,
      componentKey: 0,
    }
  },
  methods: {
    moment,
    hitungDiskonTotal() {
      this.therealtotal = this.therealsubtotal !== '' && this.therealsubtotal !== null ? parseInt(this.therealsubtotal) - parseInt(this.input.diskon) : 0
      this.masktherealtotal = this.formatCurrency(this.therealtotal !== '' && this.therealtotal !== null && !isNaN(this.therealtotal) ? this.therealtotal : 0)
    },
    selectProduk() {
      var targetKerd = this.masterBarang.findIndex((x) => x.id === this.maskInput.selectedproduct)
      var dataTargetd = this.masterBarang[targetKerd]
      this.maskInput.satuan = dataTargetd.satuan
      this.maskInput.jumlah = 1
      this.maskInput.harga = dataTargetd.harga
      // var exist = this.rowData.findIndex(x => x.id === this.maskInput.selectedproduct)
      // console.log('exist', exist)
      // if (exist < 0) {
      //   var targetKer = this.masterBarang.findIndex((x) => x.id === this.maskInput.selectedproduct)
      //   var dataTarget = this.masterBarang[targetKer]
      //   var datafd = {
      //     ...dataTarget,
      //     jumlah: 1,
      //     totalharga: 0,
      //   }
      //   this.rowData.splice(this.rowData.length - 1, 0, datafd)
      // } else {
      //   var drowData = this.rowData[exist]
      //   var targetKerd = this.masterBarang.findIndex((x) => x.id === this.maskInput.selectedproduct)
      //   var dataTargetd = this.masterBarang[targetKerd]
      //   var datafdd = {
      //     ...dataTargetd,
      //     jumlah: parseInt(drowData.jumlah) + 1,
      //     totalharga: 0,
      //   }
      //   var cofrwdt = this.$g.clone(this.rowData)
      //   var targetKerd1 = cofrwdt.findIndex((x) => x.id === this.maskInput.selectedproduct)
      //   cofrwdt.splice(targetKerd1, 1)
      //   cofrwdt.splice(targetKerd1, 0, datafdd)
      //   this.rowData = this.$g.clone(cofrwdt)
      // }
      // this.maskInput.selectedproduct = ''
    },
    filterDate(date, dateString) {
      this.tgl1 = date
      this.getAllData()
    },
    async getAllData() {
      var imbuhan =
        this.tgl1 !== null && this.tgl1 !== ''
          ? '?tgl=' + this.tgl1.format('YYYY-MM-DD')
          : ''
      var res = await lou.customUrlGet2('transaksi/jurnal' + imbuhan)
      if (res) {
        this.data = res.data
      }
    },
    nextFocus(to) {
      // const input = this.$refs.rekening.$el.querySelector('input')
      // input.click()
      // this.$refs.rekening.$el.blur()
      // console.log('to', to)
      setTimeout(() => {
        this.$refs[to].$el.focus()
      }, 200)
    },
    removeFromGrid() {
      var indexTarget = this.rowData.findIndex(
        (x) => x.uuid === this.selectedData[0].uuid,
      )
      this.rowData.splice(indexTarget, 1)
    },
    bataledit() {
      this.selectedData = []
      this.maskInput = {
        sales_order_id: '',
        tgl_invoice: moment(),
      }
    },
    addDataToGrid() {
      var exist = this.rowData.findIndex(x => x.id === this.maskInput.selectedproduct)
      // console.log('exist', exist)
      if (exist < 0) {
        var targetKer = this.masterBarang.findIndex((x) => x.id === this.maskInput.selectedproduct)
        var dataTarget = this.masterBarang[targetKer]
        var datafd = {
          ...dataTarget,
          satuan: this.maskInput.satuan === '' ? dataTarget.satuan : this.maskInput.satuan,
          jumlah: this.maskInput.jumlah === '' ? 1 : this.maskInput.jumlah,
          harga: this.maskInput.harga === '' ? dataTarget.harga : this.maskInput.harga,
          totalharga: 0,
        }
        this.rowData.splice(this.rowData.length - 1, 0, datafd)
      } else {
        // var drowData = this.rowData[exist]
        var targetKerd = this.masterBarang.findIndex((x) => x.id === this.maskInput.selectedproduct)
        var dataTargetd = this.masterBarang[targetKerd]
        var datafdd = {
          ...dataTargetd,
          satuan: this.maskInput.satuan === '' ? dataTarget.satuan : this.maskInput.satuan,
          jumlah: this.maskInput.jumlah === '' ? 1 : this.maskInput.jumlah,
          harga: this.maskInput.harga === '' ? dataTarget.harga : this.maskInput.harga,
          totalharga: 0,
        }
        var cofrwdt = this.$g.clone(this.rowData)
        var targetKerd1 = cofrwdt.findIndex((x) => x.id === this.maskInput.selectedproduct)
        cofrwdt.splice(targetKerd1, 1)
        cofrwdt.splice(targetKerd1, 0, datafdd)
        this.rowData = this.$g.clone(cofrwdt)
      }
      // if (this.selectedData.length === 0) {
      //   var targetKer = this.masterRekening.findIndex((x) => x.kode === this.maskInput.rekening)
      //   var dataTarget = this.masterRekening[targetKer]
      //   var datafd = {
      //     uuid: this.$uuid.v4(),
      //     no: this.maskInput.no,
      //     id: dataTarget.id,
      //     rekening: dataTarget.kode,
      //     nama_rekening: dataTarget.keterangan,
      //     keterangan: this.maskInput.keterangan,
      //     debet: this.maskInput.debet !== '' ? this.maskInput.debet : '0',
      //     kredit: this.maskInput.kredit !== '' ? this.maskInput.kredit : '0',
      //   }
      //   this.rowData.splice(this.rowData.length - 1, 0, datafd)
      //   this.hitHitungTotal()
      // } else {
      //   var target = this.rowData.findIndex((x) => x.uuid === this.selectedData[0].uuid)
      //   if (target !== -1) {
      //     var targetrek = this.masterRekening.findIndex(
      //       (x) => x.kode === this.maskInput.rekening,
      //     )
      //     var dataTargetRek = this.masterRekening[targetrek]
      //     var data = this.rowData[target]
      //     data.id = dataTargetRek.id
      //     data.rekening = dataTargetRek.kode
      //     data.nama_rekening = dataTargetRek.keterangan
      //     data.keterangan = this.maskInput.keterangan
      //     data.debet = this.maskInput.debet !== '' ? this.maskInput.debet : '0'
      //     data.kredit = this.maskInput.kredit !== '' ? this.maskInput.kredit : '0'
      //     this.selectedData = []
      //     this.componentKey += 1
      //     this.hitHitungTotal()
      //   }
      // }
      this.selectedData = []
      this.maskInput = {
        sales_order_id: '',
        tgl_invoice: moment(),
      }
      this.hitungSubTotal()
      // const input = this.$refs.rekening.$el.querySelector('input')
      // input.focus()
    },
    hitungSubTotal() {
      var vt = 0
      this.rowData.forEach((element) => {
        vt += parseInt(element.jumlah) * parseInt(element.harga)
      })
      this.therealsubtotal = vt
      this.masktherealsubtotal = this.formatCurrency(vt)
      console.log('this.therealsubtotal', this.therealsubtotal)
      console.log('this.masktherealsubtotal', this.masktherealsubtotal)
      this.hitungDiskonTotal()
      // console.log('this.therealsubtotal', this.therealsubtotal)
    },
    hitHitungTotal() {
      var target = this.rowData.findIndex(
        (x) => x.uuid === 'totaldarisemuanya',
      )
      // console.log('this.rowData.length', this.rowData.length)
      // console.log('this.rowData[this.rowData.length - 1]', this.rowData[this.rowData.length - 1])
      // this.rowData.splice(this.rowData.length - 1, 1)
      var totaldebet = 0
      var totalkredit = 0
      this.rowData.forEach((element) => {
        if (element.uuid !== 'totaldarisemuanya') {
          totaldebet += parseFloat(element.debet)
          totalkredit += parseFloat(element.kredit)
        }
        // console.log('element.debet', element.debet)
        // console.log('element.kredit', element.kredit)
      })
      this.rowData[target].debet = totaldebet
      this.rowData[target].kredit = totalkredit
      // this.rowData.push(
      //   {
      //     uuid: 'totaldarisemuanya',
      //     rekening: '',
      //     namarek: '',
      //     keterangan: 'Total',
      //     debet: totaldebet,
      //     kredit: totalkredit,
      //   },
      // )
    },
    async getMaster() {
      this.columnDefs = [
        // {
        //   headerName: '#',
        //   width: 50,
        //   editable: false,
        //   resizable: false,
        //   sortable: false,
        //   cellRenderer: 'removeData',
        // },
        {
          headerName: 'Kode',
          field: 'kode',
          width: 80,
          editable: false,
          resizable: true,
          sortable: true,
        },
        {
          headerName: 'Nama',
          field: 'nama',
          editable: false,
          resizable: true,
          sortable: true,
        },
        {
          headerName: 'Satuan',
          field: 'satuan',
          editable: false,
          resizable: true,
          width: 120,
          sortable: true,
        },
        {
          headerName: 'Jumlah',
          field: 'jumlah',
          width: 80,
          editable: true,
          resizable: true,
          cellEditor: 'numericEditor',
          sortable: true,
          type: 'rightAligned',
          // valueGetter: params => {
          //   // console.log('params.data.jumlah', params.data.jumlah)
          //   return params.data.jumlah
          // },
        },
        {
          headerName: 'Harga',
          field: 'harga',
          editable: false,
          resizable: true,
          sortable: true,
          width: 150,
          type: 'rightAligned',
          valueFormatter: (params) => {
            // console.log('params', params)
            var ret = params.value === null ? 'NaN' : lou.curency(params.value)
            return ret === 'NaN' ? '' : ret
          },
        },
        {
          headerName: 'Total Harga',
          isBold: 'true',
          valueGetter: params => {
            // console.log('params.data.jumlah', params.data.jumlah)
            var total = parseInt(params.data.harga) * parseInt(params.data.jumlah)
            return total
          },
          editable: false,
          resizable: true,
          sortable: true,
          width: 150,
          type: 'rightAligned',
          valueFormatter: (params) => {
            // console.log('params', params)
            var ret = params.value === null ? 'NaN' : lou.curency(params.value)
            return ret === 'NaN' ? '' : ret
          },
        },
      ]
      this.componentKey += 1
      // var resmasterBarang = await lou.customUrlGet2('transaksi/rilis_invoice/barang')
      // var resmasterCustomer = await lou.customUrlGet2('transaksi/rilis_invoice/customer')
      // var resmasterEmployee = await lou.customUrlGet2('transaksi/rilis_invoice/employee')
      // if (resmasterCustomer) {
      //   this.masterCustomer = []
      //   resmasterCustomer.data.forEach((element) => {
      //     var nobj = {
      //       ...element,
      //       label: element.nama_perusahaan + ' - ' + element.nama_kontak,
      //     }
      //     this.masterCustomer.push(nobj)
      //   })
      //   // console.log('this.masterRekening', this.masterRekening)
      // }
      // if (resmasterEmployee) {
      //   this.masterEmployee = []
      //   resmasterEmployee.data.forEach((element) => {
      //     var nobj = {
      //       ...element,
      //       label: element.nama + ' - ' + element.hp,
      //     }
      //     this.masterEmployee.push(nobj)
      //   })
      //   // console.log('this.masterRekening', this.masterRekening)
      // }
      // if (resmasterBarang) {
      //   this.masterBarang = []
      //   resmasterBarang.data.forEach((element) => {
      //     var nobj = {
      //       ...element,
      //       label: element.kode + ' - ' + element.nama + ' - ' + element.harga,
      //     }
      //     this.masterBarang.push(nobj)
      //   })
      //   // console.log('this.masterRekening', this.masterRekening)
      // }
      if (this.editdata !== null && this.editdata.id !== null && this.editdata.id !== '') {
        this.action = 'update'
        this.title = 'Update Rilis Invoice'
        this.disabledAllInputExcludeNoRekening = true
        this.getEditData()
      } else {
        this.action = 'create'
        this.title = 'Create Rilis Invoice'
        // this.tempInput.f2digitreg = this.user.user_branch
      }
      // this.rowData.push({
      //   uuid: 'totaldarisemuanya',
      //   rekening: '',
      //   namarek: '',
      //   keterangan: 'Total',
      //   debet: 0,
      //   kredit: 0,
      // })
    },
    async handleSubmit() {
      // console.log('this.rowData', this.rowData)
      // if (this.rowData.length >= 1) {
      // Modal.confirm({
      //   title: 'Apakah anda yakin data sudah benar?',
      //   content: 'Data tidak dapat di edit ataupun di hapus nanti!',
      //   okText: 'Yes',
      //   okType: 'danger',
      //   cancelText: 'No',
      //   onOk: async () => {
      var fd = this.$g.clone(this.maskInput)
      fd.tgl_invoice = this.maskInput.tgl_invoice.format('YYYY-MM-DD')
      // if (fd.redoInput !== undefined) { delete fd.redoInput }
      // if (fd.redoInputdisable !== undefined) { delete fd.redoInputdisable }
      // if (fd.undoInput !== undefined) { delete fd.undoInput }
      // if (fd.undoInputdisable !== undefined) { delete fd.undoInputdisable }
      // fd.details = this.$g.clone(this.rowData)
      // fd.sub_total = parseInt(this.therealsubtotal)
      // fd.total = parseInt(this.therealsubtotal - this.input.diskon)
      // fd.details.forEach((element) => {
      //   if (element.label !== undefined) { delete element.label }
      //   element.product_id = element.id
      //   element.jumlah = parseInt(element.jumlah)
      //   element.harga = parseInt(element.harga)
      //   delete element.id
      //   delete element.nama
      //   delete element.kode
      //   delete element.satuan
      //   delete element.totalharga
      //   delete element.total
      // })
      // console.log('fd details', fd)
      var res = null
      if (this.action === 'create') {
        delete fd.id
        res = await lou.customUrlPost2('transaksi/rilis_invoice', fd)
      } else {
        res = await lou.customUrlPut2('transaksi/rilis_invoice', fd)
      }
      if (res) {
        this.resetForm()
        // this.getAllData()
      }
      //   },
      //   onCancel: () => {
      //     // console.log('Cancel')
      //     return false
      //   },
      // })
      // } else {
      //   lou.shownotif('Peringatan!', 'Tidak ada data yang diinput.', 'error')
      // }
    },

    resetForm(back = false) {
      this.editdata = {}
      this.action = 'create'
      this.selectedData = []
      this.maskInput = {
        sales_order_id: '',
        tgl_invoice: moment(),
      }
      this.input = {}
      this.rowData = []
      if (back) {
        localStorage.removeItem('projectatransaksirilisinvoice')
        this.$parent.active = 0
        // this.$router.push({ path: '/projecta/transaksi/orderpenjualan' })
      }
    },
    // ag-grid function
    onGridReady(params) {
      // params.api.sizeColumnsToFit()
      this.gridApi = params.api
      this.gridColumnApi = params.columnApi
    },
    onCellValueChanged(params) {
      // var undoSize = params.api.getCurrentUndoSize()
      // this.input.undoInput = undoSize
      // this.input.undoInputdisable = undoSize < 1
      // var redoSize = params.api.getCurrentRedoSize()
      // this.input.redoInput = redoSize
      // this.input.redoInputdisable = redoSize < 1
      if (this.mode === 'oncreate') {
        if (params.data.newdata) {
          var target = this.changedData.findIndex(
            (x) => x.id === params.data.id,
          )
          if (target < 0) {
            this.changedData.push(params.data)
          } else {
            this.changedData[target] = params.data
          }
          // console.log('this.changedData', this.changedData)
        } else {
          // eslint-disable-next-line no-redeclare
          var target = this.rowData.findIndex((x) => x.id === params.data.id)
          var backupTarget = this.backupRowData.findIndex(
            (x) => x.id === params.data.id,
          )
          // console.log('this.rowData[target] before:', this.rowData[target])
          this.rowData[target] = this.$g.clone(
            this.backupRowData[backupTarget],
          )
          // console.log('this.rowData[target] after:', this.rowData[target])
          // console.log('this.backupRowData[backupTarget]', this.backupRowData[backupTarget])
          // this.componentKey += 1
          this.changedData = []
          this.input.undoInput = 0
          this.input.undoInputdisable = true

          this.input.redoInput = 0
          this.input.redoInputdisable = true
          lou.shownotif('Anda sedang dalam mode create')
        }
      } else {
        this.mode = 'onupdate'
        // eslint-disable-next-line no-redeclare
        var targetChanged = this.changedData.findIndex(
          (x) => x.id === params.data.id,
        )
        if (targetChanged < 0) {
          // var cd = {}
          // cd.id = params.data.id
          // cd[params.column.colId] = value.value
          // console.log('cd[params.column.colId]', cd)
          // console.log('value.value', value)
          this.changedData.push(params.data)
        } else {
          this.changedData[targetChanged][params.column.colId] =
            params.newValue
        }
        // console.log('this.changedData', this.changedData)
        // this.componentKey += 1
      }
      this.hitungSubTotal()
      // console.log('this.mode', this.mode)
    },
    onSelectionChanged() {
      var selectedRows = this.gridApi.getSelectedRows()
      if (selectedRows[0].uuid !== 'totaldarisemuanya') {
        this.selectedData = selectedRows
        this.maskInput.selectedproduct = selectedRows[0].id
        this.maskInput.id = selectedRows[0].id
        this.maskInput.satuan = this.selectedData[0].satuan
        this.maskInput.jumlah = this.selectedData[0].jumlah
        this.maskInput.harga = this.selectedData[0].harga
      } else {
        lou.shownotif('Total', 'Total akan terupdate secara otomatis.')
      }
      // console.log('selectedRows', selectedRows)
    },
    onFirstDataRendered() {
      // this.autoSizeAll(false, { autosizecolumn: true })
    },
    autoSizeAll(skipHeader, { autosizecolumn = true }) {
      if (autosizecolumn) {
        var allColumnIds = []
        // console.log('this.gridColumnApi', this.gridColumnApi)
        this.gridColumnApi.getAllColumns().forEach(function (column) {
          allColumnIds.push(column.colId)
        })
        this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader)
      } else {
        this.gridApi.sizeColumnsToFit()
      }
    },

    getEditData() {
      this.action = 'update'
      this.selectedData = []
      this.maskInput = {
        id: 0,
        sales_order_id: '',
        tgl_invoice: moment(),
      }
      if (this.editdata.id === null) {
        this.action = 'create'
        delete this.maskInput.id
      } else {
        this.action = 'update'
        this.maskInput.id = this.editdata.id
      }
      this.maskInput.sales_order_id = this.editdata.sales_order_id
      this.input = this.$g.clone(this.editdata)
      // console.log('this.input', this.input)
      this.input.tgl = moment(this.editdata.tgl, 'YYYY-MM-DD').format('DD-MM-YYYY')
      this.rowData = []
      var tempdata = this.$g.clone(this.editdata.details)
      tempdata.forEach(element => {
        // var idbarang = this.masterBarang.findIndex(x => x.id === element.product_id)
        // var dbarang = this.masterBarang[idbarang]
        var dfasdf = {
          ...element,
          nama: element.product_name,
          kode: element.kode,
          satuan: element.satuan,
          harga: element.harga,
          id: element.product_id,
        }
        this.rowData.push(dfasdf)
      })
      this.therealsubtotal = this.formatCurrency(this.editdata.sub_total)
      this.masktherealsubtotal = this.formatCurrency(this.editdata.sub_total)
      this.input.diskon = this.formatCurrency(this.editdata.diskon)
      this.therealtotal = this.formatCurrency(this.editdata.total)
      this.masktherealtotal = this.formatCurrency(this.editdata.total)
      // this.hitungSubTotal()
      // this.hitungDiskonTotal()
      // this.hitHitungTotal()
    },
    showDeleteConfirm(deldata) {
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          var response = await lou.customUrlDelete2('transaksi/jurnal?faktur=' + deldata)
          if (response) {
            this.getAllData()
          }
        },
        onCancel: () => {
          console.log('Cancel')
        },
      })
    },
    dateFormatter(params) {
      var ret =
        params.value === null
          ? ''
          : moment(params.value, 'YYYY-MM-DD').format('DD-MM-YYYY')
      // console.log('dateFormatter', ret)
      return ret !== 'Invalid date' ? ret : params.value
    },
    currencyFormatter(params) {
      var ret =
        params.value === null
          ? 'NaN'
          : lou.curency(parseFloat(params.value).toFixed(0))
      return ret === 'NaN' ? '' : ret
    },
    formatTanggal(v) {
      // console.log('v', v)
      // console.log('ntgl', ntgl)
      return moment(v.tgl, 'YYYY-MM-DD').format('DD-MM-YYYY')
    },
    formatCurrency(v) {
      return lou.curency(v)
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    getTotalDetails(what, data) {
      var total = 0

      data.forEach(element => {
        total += element[what]
      })

      return total
    },
    back() {
      this.$parent.active = 0
      // this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss" scoped></style>
