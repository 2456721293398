var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-card',[_c('template',{slot:"title"},[_c('strong',[_vm._v("Rilis Invoice")]),_c('a-range-picker',{staticClass:"ml-3 mr-2",staticStyle:{"width":"250px"},attrs:{"allowClear":false,"default-value":[_vm.moment(), _vm.moment()],"format":"DD-MM-YYYY"},on:{"change":_vm.filterDate}}),_c('a-button-group',[_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("Refresh Data")])]),_c('a-button',{on:{"click":function($event){return _vm.getAllData(true)}}},[_c('a-icon',{staticClass:"text-success",attrs:{"type":"sync","spin":_vm.loading}})],1)],2)],1)],1),_c('a-table',{staticStyle:{"margin":"-25px -25px"},attrs:{"columns":_vm.columns,"dataSource":_vm.data,"pagination":{
      hideOnSinglePage: true,
      defaultPageSize: 10,
      showQuickJumper: true,
      showSizeChanger: true,
      showTotal: (total) => `Total ${total} items`,
      pageSizeOptions: ['10', '20', '30'],
    },"size":"small","loading":_vm.loading},scopedSlots:_vm._u([{key:"filterDropdown",fn:function({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        column,
      }){return _c('div',{staticStyle:{"padding":"8px"}},[_c('a-input',{directives:[{name:"ant-ref",rawName:"v-ant-ref",value:((c) => (_vm.searchInput = c)),expression:"(c) => (searchInput = c)"}],staticStyle:{"width":"188px","margin-bottom":"8px","display":"block"},attrs:{"placeholder":`Search ${column.title}`,"value":selectedKeys[0]},on:{"change":(e) => setSelectedKeys(e.target.value ? [e.target.value] : []),"pressEnter":() => _vm.handleSearch(selectedKeys, confirm, column.dataIndex)}}),_c('a-button',{staticStyle:{"width":"90px","margin-right":"8px"},attrs:{"type":"primary","icon":"search","size":"small"},on:{"click":() => _vm.handleSearch(selectedKeys, confirm, column.dataIndex)}},[_vm._v(" Search ")]),_c('a-button',{staticStyle:{"width":"90px"},attrs:{"size":"small"},on:{"click":() => _vm.handleReset(clearFilters)}},[_vm._v(" Reset ")])],1)}},{key:"filterIcon",fn:function(filtered){return _c('a-icon',{style:({ color: filtered ? '#108ee9' : undefined }),attrs:{"type":"search"}})}},{key:"customRender",fn:function(text, record, index, column){return [(_vm.searchText && _vm.searchedColumn === column.dataIndex)?_c('span',[_vm._l((text
            .toString()
            .split(new RegExp(`(?<=${_vm.searchText})|(?=${_vm.searchText})`, 'i'))),function(fragment,i){return [(fragment.toLowerCase() === _vm.searchText.toLowerCase())?_c('mark',{key:i,staticClass:"highlight"},[_vm._v(_vm._s(fragment))]):[_vm._v(_vm._s(fragment))]]})],2):[_vm._v(" "+_vm._s(text)+" ")]]}},{key:"action",fn:function(text, record, index){return _c('span',{},[_c('a',{staticClass:"text-warning",on:{"click":function($event){return _vm.sendToEdit(record)}}},[_c('a-icon',{attrs:{"type":"edit"}})],1),_c('a-divider',{attrs:{"type":"vertical"}}),_c('a',{staticClass:"text-danger",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.showDeleteConfirm(record)}}},[_c('a-icon',{attrs:{"type":"delete"}})],1),_c('a-divider',{attrs:{"type":"vertical"}}),(record.status_invoice === 'Belum diproses')?_c('a',{staticClass:"text-secondary",attrs:{"href":"javascript:void(0)"}},[_c('a-icon',{attrs:{"type":"printer"}})],1):_vm._e(),(record.status_invoice !== 'Belum diproses')?_c('a',{staticClass:"text-success",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.exportPdf(record, index)}}},[_c('a-icon',{attrs:{"type":"printer"}})],1):_vm._e()],1)}},{key:"tgl",fn:function(text){return _c('span',{},[_vm._v(" "+_vm._s(_vm.formatDate(text))+" ")])}},{key:"formatCurrency",fn:function(text){return _c('span',{},[_vm._v(" "+_vm._s(_vm.formatCurrency(text))+" ")])}},{key:"statusinvoice",fn:function(text, record){return _c('span',{},[(record.tgl_invoice === null)?_c('a-tag',{attrs:{"color":"#f50"}},[_vm._v(" "+_vm._s(text)+" ")]):_c('a-tag',{attrs:{"color":"#87d068"}},[_vm._v(" "+_vm._s(text)+" ")])],1)}},{key:"expandedRowRender",fn:function(record){return [_c('a-table',{attrs:{"columns":_vm.columnChildren,"dataSource":record.details,"size":"small","pagination":{
          hideOnSinglePage: true,
          defaultPageSize: 10,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
          pageSizeOptions: ['10', '20', '30'],
        }},scopedSlots:_vm._u([{key:"formatCurrency",fn:function(text){return _c('span',{},[_vm._v(" "+_vm._s(_vm.formatCurrency(text))+" ")])}}],null,true)})]}}])})],2),_c('a-modal',{attrs:{"visible":_vm.amodalvisible,"dialogStyle":{ top: '10px' },"width":"","footer":null}},[_c('template',{slot:"title"},[_c('strong',[_vm._v("Preview")]),_c('a-button-group',{staticClass:"ml-2"},[_c('a-button',{staticClass:"btn btn-outline-success",on:{"click":_vm.downloadPDF}},[_vm._v("Download")]),_c('a-button',{staticClass:"btn btn-outline-warning",on:{"click":_vm.printPDF}},[_vm._v("Print")])],1)],1),_c('a-icon',{attrs:{"slot":"closeIcon","type":"close"},on:{"click":function($event){_vm.amodalvisible=false}},slot:"closeIcon"}),_c('pdf',{key:_vm.componentKey,ref:"pdfViewer",staticClass:"pdf-viewer",attrs:{"src":_vm.amodal.ArrayBuffer}}),_c('iframe',{staticStyle:{"display":"none"},attrs:{"id":"pdfFrame"}})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }